<template>
  
    <form role="form" @submit="onFormSubmit" name="component-form">

      <div class="row" v-if="showComponentPath">
        <div class="col-lg-12">
          <h3>{{ getLocalizedText(labels.createFromTitle) }}</h3>
        </div>
      </div>
      <Select 
        v-if="showComponentPath"
        :selectOptions="{
          options: createFromComponents,
          getOptionLabel : component => getComponentName(component)
        }"
        v-bind:value.sync="createFromForm.component" 
        id="createFromComponent" 
        :label="getLocalizedText(labels.createFromComponent)" 
        :placeholder="getLocalizedText(labels.createFromComponent_placeholder)"
        labelAsColumn 
      >
        <template v-slot:buttons>
            <button class="btn btn-primary create-from" type="button" @click="createFromComponent()">{{ getLocalizedText(labels.createFromComponentButton) }}</button>
        </template>
      </Select>
      
      <h3 v-if="showComponentPath">{{ getLocalizedText(labels.componentType) }}</h3>
      
      <Select
        v-if="showComponentPath"
        :label="getLocalizedText(labels.componentPath)"
        :placeholder="getLocalizedText(labels.componentPath_placeholder)"
        :selectOptions="{
          options: availableComponents
        }"
        v-bind:value.sync="form.path" 
        :labelAsColumn="true"
      />

      <div v-if="form.path">

        <component v-for="(componentStucture, index) in computedComponentStructure" :key="index + '-component'" :is="componentStucture.component" v-bind="componentStucture.props" v-on="componentStucture.events">{{componentStucture.value}}</component>

        <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">{{buttonLabel}}</button>

      </div>
    </form>

</template>


<script lang="ts">
import { toRefs, Ref, ComputedRef, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import LocalizedText from '@fwk-client/components/panels/input/LocalizedText.vue';
import TextField from '@fwk-client/components/panels/input/TextField.vue';
import SwitchCheck from '@fwk-client/components/panels/input/SwitchCheck.vue';
import Select from '@fwk-client/components/panels/input/Select.vue';
import LocalizedContent from '@root/src/client/components/panels/input/LocalizedContent.vue';
import Picture from '@root/src/client/components/panels/input/Picture.vue';
import File from '@root/src/client/components/panels/input/File.vue';

import { roles as apiRoles } from '@igotweb/core-api/src/roles';
import { authenticationTypes } from '@fwk-client/store/types';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';
import { getPath, setPath } from '@igotweb-node-api-utils/misc';

import { useSiteAdmin } from '../../../composables/useSiteAdmin';
import { useRouteAdmin } from '../../../composables/useRouteAdmin';
import { getComputedMediaURL } from '@fwk-client/utils/media';



export default defineComponent({
  props: {
      slotToUpdate: {
        type: String,
        required: true
      },
      componentsPerSlots: {
        type: Object as PropType<{[slot:string]:any}>,
        required: true
      },
      route: {
        type: Object as PropType<any>,
        required: true
      },
      component: {
        type: Object as PropType<any>,
        required: false
      },
      staticsDomain: {
        type: String,
        required: true
      }
  },
  components: {
    LocalizedText, LocalizedContent, TextField, SwitchCheck, Select, Picture, File
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();
    const { sites, selectedSite, selectedSiteTopLevelDomain } = useSiteAdmin(props, context);
    const { getAvailableComponentsForRoute, getComponentName, getComponentsFromSlot
      } = useRouteAdmin(props, context);

    const { component, route, slotToUpdate, componentsPerSlots, staticsDomain } = toRefs(props);

    const form:Ref<any> = ref({
      path: undefined
    });

    const staticsForm:Ref<{[key:string]:File}> = ref({});

    const updateFormForUpdate = (component:any) => {
      if(component) {
        form.value = {
          path:component.path,
          ...JSON.parse(JSON.stringify(component.props))
        }
      }
    }

    const createFromForm:Ref<any> = ref({});
    const componentStructure:Ref<any> = ref({});

    var laddaSubmit:Ladda.LaddaButton|null = null;

    const labels = {
      "componentPath" : {
        "fr" : "Composant",
        "en" : "Component"
      },
      "componentPath_placeholder" : {
        "fr" : "Sélectionnez un composant",
        "en" : "Select a component"
      },
      "componentType": {
        "fr" : "Type de composant",
        "en" : "Component type"
      },
      "createFromTitle": {
        "fr" : "Copier un composant existant de la page",
        "en" : "Copy an existing component from the page"
      },
      "createFromComponent": {
        "fr" : "Composant de la page",
        "en" : "Page component"
      },
      "createFromComponent_placeholder" : {
        "fr" : "Sélectionnez un composant",
        "en" : "Select a component"
      },
      "createFromComponentButton" : {
        "fr" : "Copier",
        "en" : "Copy"
      }
    }

    const availableLanguageCodes = ['fr','en'];

    const showComponentPath = computed(() => {
      return component == undefined || component.value == undefined || component.value.path == "";
    })

    const availableComponents:Ref<any[]> = ref([]);

    const createFromComponent = () => {
      if(createFromForm.value.component) {
          updateFormForUpdate(createFromForm.value.component);
      }
    }

    const createFromComponents = computed(() => {
      return getComponentsFromSlot(slotToUpdate.value, componentsPerSlots.value);
    })

    const buttonLabel = computed(() => {
      if(component && component.value && component.value.path != "") { return app.$t('cms.site.routes.components.update.button') }
      else { return app.$t('cms.site.routes.components.create.button'); }
    })

    const computedComponentStructure = computed(() => {
      if(componentStructure.value) {
        var allowOptions = $store.getters['authentication/' + authenticationTypes.getters.HAS_USER_ROLE](apiRoles.superadmin);
        var structure = [];
        var options = computeComponentStructure(componentStructure.value, "options");
        var optionsOnly = options.filter((option:any) => {
          return option.component !== "Picture";
        });
        var picturesOnly = options.filter((option:any) => {
          return option.component === "Picture";
        });
        var labels = computeComponentStructure(componentStructure.value, "labels");
        
        if(optionsOnly.length > 0 && allowOptions) {
          structure.push({
            component: "h3",
            value: "Options"
          });
          structure.push(...optionsOnly);
        }

        if(picturesOnly.length > 0) {
          structure.push({
            component: "h3",
            value: "Images"
          });
          structure.push(...picturesOnly);
        }
        
        if(labels.length > 0) {
          structure.push({
              component: "h3",
              value: "Labels"
            })
          structure.push(...labels);
        }
        return structure;
      }
      return [];
    });

    const computeComponentStructure = (structure:any, path?:string) => {
      var componentStack:any[] = [];
      var structureFromPath = structure;
      if(path) {
        structureFromPath = getPath(structure, path);
      }
      else {
        path = "";
      }

      if(!structureFromPath) { return [] }
      
      var keys = Object.keys(structureFromPath);

      for(var key of keys) {
        var value = structureFromPath[key];

        // We need to go within the object
        var subPath = (path == "") ? key : path + "." + key;

        if(value.type && typeof value.type == "string") {
          // We have a field
          componentStack.push({
            component: getFieldComponent(value.type),
            props: getFieldComponentProps(value, key, subPath),
            events: getFieldEvents(value.type, subPath)
          })

        }
        else {
          // We have a structure option
          componentStack.push({
            component: "h3",
            value: key
          })
          
          componentStack.push(
            ...computeComponentStructure(structure, subPath)
          )
        }
        
      }
      return componentStack;
    }

    /**
     * We get the component used to update the field based on the type
     */
    const getFieldComponent = (type:string) => {
      switch(type) {
        case "CmsLabel": 
          return "LocalizedText"
        case "CmsContent": 
          return "LocalizedContent"
        case "CmsText":
        case "CmsNumber":
          return "TextField"
        case "CmsBoolean":
          return "SwitchCheck"
        case "CmsEnum":
          return "Select"
        case "CmsPicture":
          return "Picture"
        case "CmsFile":
          return "File"
      }

      return null;
    }

    /**
     * We get the component props used to update the field based on the type and path to the value
     */
    const getFieldComponentProps = (value:any, key:string, path:string) => {
      // We get the input from the form
      var input = getPath(form.value, path);

      var defaultProps = {
        "value" : input,
        "label" : key,
        "placeholder" : key,
        "required" : false,
        "labelAsColumn" : true
      };

      switch(value.type) {
        case "CmsLabel": 
          // If we have string value we need to convert it in localized text
          if(typeof defaultProps.value == "string") {
            defaultProps.value = {
              [availableLanguageCodes[0]] : defaultProps.value
            }
          }
          return {
            ...defaultProps,
            "languageCodes" : availableLanguageCodes,
          }
        case "CmsContent": 
          return {
            ...defaultProps,
            "options" : {
              "allowSourceEditing" : true,
              // "editor": "tinymce"
            },
            "languageCodes" : availableLanguageCodes
          }
        case "CmsText": 
          return {
            ...defaultProps
          }
        case "CmsNumber":
          return {
            ...defaultProps,
            "fieldType" : "number"
          }
        case "CmsEnum": 
          return {
            ...defaultProps,
            "selectOptions" : {
              options: value.values
            }
          }
        case "CmsBoolean": 
          return {
            ...defaultProps
          }
        case "CmsFile": 
          return {
            ...defaultProps,
            "labels" : {
              "label" : key
            },
            "file" : getComputedMediaURL(input, staticsDomain.value)
          }
        case "CmsPicture": 
          return {
            ...defaultProps,
            "labels" : {
              "label" : key
            },
            "picture" : getComputedMediaURL(input, staticsDomain.value)
          }
      }
      return null;
    }

    const getFieldEvents = (type:string, path:string) => {
      // We get the input from the form
      var input = getPath(form.value, path);

      switch(type) {
        case "CmsLabel": 
        case "CmsContent": 
        case "CmsText": 
        case "CmsNumber": 
        case "CmsBoolean": 
        case "CmsEnum": 
          return {
            "update:value" : ($event:any) => { 
              setPath(form.value, path, $event);
            }
          }
        case "CmsPicture": 
        case "CmsFile": 
          return {
            "update:value" : ($event:any) => { 
              staticsForm.value[path] = $event;
            }
          }
      }
      return null;
    }

    onMounted(() => {
      if(props.component && props.component.path != "") {
        var button:HTMLButtonElement|null = document.querySelector( 'form[name=component-form] button' );
        laddaSubmit = Ladda.create(button!);
      }
    })

    const getComponentStructure = (componentPath:string) => {
      // We need to get the list of available companies for the current logged in user
      var options:api.ApiVueOptions =  {
        app: app
      }
      var input:any = {
        path : componentPath
      }
      api.postAPI('/api/admin/cms/site/'+selectedSite.value.site._id+'/routes/component/structure', input, options).then((response:any) => {
        if(response.structure) {  
          componentStructure.value = response.structure;
        }
      });
    }

    if(props.component && props.component.path != "") {
      getComponentStructure(component.value.path);
      updateFormForUpdate(component.value);
    }
    else {
      // We load the available components
      getAvailableComponentsForRoute(route.value).then((result) => {
        if(result.components) {
          availableComponents.value = result.components;
        }
      })
    }

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaSubmit!.start();

      if(component.value && component.value.path != "") {
        let itemUpdatedOutput = {
          component: {
            ...component.value,
            props : form.value,
          },
          statics: staticsForm.value
        }
        delete itemUpdatedOutput.component.props.path;

        context.emit('component-updated', itemUpdatedOutput);
      }
      else {
        let itemCreatedOutput = {
          component : {
            path: form.value.path,
            props : JSON.parse(JSON.stringify(form.value)),
          },
          statics: staticsForm.value
        }
        delete itemCreatedOutput.component.props.path;

        context.emit('component-added', itemCreatedOutput);
      }
    }

    watch(
      () => form.value.path,
      (val:any, oldVal:any) => {
        if(val != undefined && val != "") {
          var button:HTMLButtonElement|null = document.querySelector( 'form[name=component-form] button' );
          laddaSubmit = Ladda.create(button!);

          if(showComponentPath.value) {
            getComponentStructure(val);
          }
        }
      },
      { deep: true }
    )

    watch(
      () => component,
      (val:any, oldVal:any) => {
        
        if(laddaSubmit) { laddaSubmit!.stop(); }
        
        if(val != undefined && val.path != "") {
          getComponentStructure(val.path);
          updateFormForUpdate(val);
        }
      }
    )

    return {
      labels,
      form,
      staticsForm,
      showComponentPath,
      availableComponents,
      onFormSubmit,
      computedComponentStructure,
      buttonLabel,
      getComponentName,
      createFromComponents,
      createFromForm,
      createFromComponent
    }
  }
})
</script>