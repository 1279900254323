<template>
  
    <form role="form" @submit="onFormSubmit" name="publish-route-components">

      <h4>{{$t('cms.site.routes.publish.components')}}</h4>
      
      <div v-if="isProductionLoading" class="sk-spinner sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>
      <ul v-else>
        <li v-for="(slot, index) in availableSlots" :key="index + '-' + slot">{{ $t('cms.site.routes.components.slots.'+slot) }}
          <ul v-if="componentsPerSlotsToDisplay[slot] && componentsPerSlotsToDisplay[slot].length > 0">
            <li v-for="(component, index) in componentsPerSlotsToDisplay[slot]" :key="index + '-' + component.path" :class="{'component-updated':component.isUpdated, 'component-new':component.isNew, 'component-removed':component.isRemoved}">
              {{ component.path }} 
              <span v-if="component.name"> - {{ component.name }}</span>
              <span v-if="component.isUpdated"> -> {{ $t('cms.site.routes.components.updated') }}</span>
              <span v-else-if="component.isNew"> -> {{ $t('cms.site.routes.components.added') }}</span>
              <span v-else-if="component.isRemoved"> -> {{ $t('cms.site.routes.components.removed') }}</span>
            </li>
          </ul>
          <ul v-else><li>{{$t('cms.site.routes.publish.no-slot-components')}}</li></ul>
        </li>
      </ul>
      
      <button class="btn btn-primary ladda-button publish-route-components" data-style="zoom-in" type="submit">{{$t('cms.site.routes.publish.button')}}</button>
    </form>

</template>

<style scoped>
  .fa-check.active, .fa-user-circle-o.active {
    color:green;
  }
  .fa-check.disabled, .fa-user-circle-o.disabled {
    color:red;
  }

  .component-updated {
    color:orange;
  }
  .component-removed {
    color:red;
  }
  .component-new {
    color:green;
  }

  
</style>

<script lang="ts">
import { toRefs, Ref, ref, defineComponent, PropType, computed, onMounted } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';
import { languagesTypes } from '@fwk-client/store/types';
import { formatDate } from '@igotweb-node-api-utils/formatter';

import { useSiteAdmin } from '../../../composables/useSiteAdmin';
import { useRouteAdmin } from '../../../composables/useRouteAdmin';

export default defineComponent({
  props: {
    route: Object as PropType<any>,
    componentsPerSlots: Object as PropType<{[slot:string]:any}>,
  },
  components: {
      
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { selectedSite } = useSiteAdmin(props, context);
    const { publishRouteComponents, getProductionRoute, getComponentName, getComponentPath, getComponentsFromSlot } = useRouteAdmin(props, context);

    const productionComponentsPerSlots:Ref<{[slot:string]:any}|undefined> = ref(undefined);

    const { route, componentsPerSlots } = toRefs(props);

    var laddaSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var publishButton:HTMLButtonElement|null = document.querySelector( 'form[name=publish-route-components] button.ladda-button.publish-route-components' );
      laddaSubmit = Ladda.create(publishButton!);
    })

    const isProductionLoading:Ref<boolean> = ref(false);
    const getProductionComponents = () => {
      isProductionLoading.value = true;
      getProductionRoute(route.value).then((results:any) => {
        isProductionLoading.value = false;
        if(results.retrieved) {
          productionComponentsPerSlots.value = results.components;
        }
        else {
          productionComponentsPerSlots.value = undefined;
        }
      })
    }
    // We load the production components.
    getProductionComponents();

    const availableSlots = computed(() => {
      var componentsPerSlotsNames = componentsPerSlots.value ? Object.keys(componentsPerSlots.value) : [];
      var productionComponentsPerSltsNames = productionComponentsPerSlots.value ? Object.keys(productionComponentsPerSlots.value) : [];
      return [...new Set([...componentsPerSlotsNames, ...productionComponentsPerSltsNames])]
    })

    /**
     * productionRemovedComponentsPerSlots
     * We get the list of components removed from production
     */
    const productionRemovedComponentsPerSlots:Ref<null|{[slot:string]:{path:string, name:string, previousComponentID?:string}[]}> = computed(() => {
      if(!productionComponentsPerSlots.value) {
        return null;
      }
      var removecComponentsPerSlots:{[slot:string]:{path:string, name:string, previousComponentID?:string}[]} = {};
      for(var slot of availableSlots.value) {
        removecComponentsPerSlots[slot] = [];
        var productionComponents = getComponentsFromSlot(slot, productionComponentsPerSlots.value)
        if(productionComponents.length > 0) {
          var previousComponentID:string|undefined = undefined;
          for(var productionComponent of productionComponents) {
            // We check if the component has been removed
            var isRemoved = true;
            if(componentsPerSlots.value && componentsPerSlots.value[slot]) {
              var slotComponents = getComponentsFromSlot(slot, componentsPerSlots.value)
              for(var component of slotComponents) {
                if(component.id && productionComponent.id && component.id == productionComponent.id) {
                  isRemoved = false;
                  break;
                } 
              }
            }

            if(isRemoved) {
              // We store it
              removecComponentsPerSlots[slot].push({
                path: getComponentPath(productionComponent),
                name: getComponentName(productionComponent),
                previousComponentID: previousComponentID
              })
            }
            else {
              // We update the previous component ID which is not removed
              previousComponentID = productionComponent.id
            }
          }
        }
      }
      return removecComponentsPerSlots;
    })

    const componentsPerSlotsToDisplay = computed(() => {
      var toDisplay:{[slot:string]:{path:string, name:string, isUpdated:boolean, isNew:boolean, isRemoved:boolean}[]} = {};
      if(!componentsPerSlots.value) {
        return toDisplay;
      }

      for(var slot of availableSlots.value) {
        toDisplay[slot] = [];
        var slotComponents = getComponentsFromSlot(slot, componentsPerSlots.value)
        if(slotComponents.length > 0) {
          for(var component of slotComponents) {
            var isUpdated = false;
            var isNew = true;
            if(productionComponentsPerSlots.value && productionComponentsPerSlots.value[slot]) {
              var slotProductionComponents = getComponentsFromSlot(slot, productionComponentsPerSlots.value)
              for(var productionComponent of slotProductionComponents) {
                if(component.id && productionComponent.id && component.id == productionComponent.id) {
                  isNew = false;
                  if(JSON.stringify(component) !== JSON.stringify(productionComponent)) {
                    isUpdated = true
                  }
                  break;
                } 
              }
            }

            toDisplay[slot].push({
              path: getComponentPath(component),
              name: getComponentName(component),
              isUpdated: isUpdated,
              isNew: isNew,
              isRemoved: false
            })

            // We check if we have a removed component to add after this one.
            if(productionRemovedComponentsPerSlots.value && productionRemovedComponentsPerSlots.value[slot].length > 0) {
              for(var productionRemovedComponent of productionRemovedComponentsPerSlots.value[slot]) {
                if(productionRemovedComponent.previousComponentID && productionRemovedComponent.previousComponentID == component.id) {
                  toDisplay[slot].push({
                    path: productionRemovedComponent.path,
                    name: productionRemovedComponent.name,
                    isUpdated: false,
                    isNew: false,
                    isRemoved: true
                  })
                }
              }
            }
          }
        }
      }
      return toDisplay;
    })

    const onFormSubmit = (evt:Event) => {
      evt.preventDefault();

      laddaSubmit!.start();

      return publishRouteComponents(route.value).then((results:any) => {
        laddaSubmit!.stop();
        if(results.published) {  
          // We update the production components
          getProductionComponents();
          // We tell parent component
          context.emit('route-published');
        }
      })
    }

    const currentLanguageCode = $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
    
    const formatDateTime = (date:Date) => {
      return formatDate(date, currentLanguageCode);
    }

    return {
      availableSlots,
      formatDateTime,
      isProductionLoading,
      route,
      componentsPerSlotsToDisplay,
      getComponentName,
      getComponentPath,
      getComponentsFromSlot,
      onFormSubmit
    }
  }
})
</script>