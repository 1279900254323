<template>
  <section id="site" ref="siteContent">
    <PageTitle :title="$t('cms.site.theme.title')" :breadcrumbs="breadcrumbs"></PageTitle>
    
    <div class="wrapper wrapper-content animated fadeInRight">

      <Header></Header>

      <div class="row" v-if="selectedSite && selectedSite.options.isRoutesAdminAllowed">
        <div class="col">
          <RoutesBox></RoutesBox>
        </div>
      </div>

      <div class="row" v-if="selectedSite && selectedSite.options.isRoutesAdminAllowed && hasUserRole(apiRoles.superadmin)">
        <div class="col">
          <div class="ibox routes">
            <div class="ibox-title">
              <h2>{{ getLocalizedText(labels.addRouteTitle) }}</h2>
            </div>
            <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

              <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
                  <div class="sk-double-bounce1"></div>
                  <div class="sk-double-bounce2"></div>
              </div>
              <RouteForm></RouteForm>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>


<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, ComputedRef } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import PageTitle from '@root/src/client/components/panels/PageTitle.vue';
import { enableIbox, disableIbox} from '@root/src/client/theme/inspinia-jquery'; 

import { authenticationTypes } from '@fwk-client/store/types';
import { roles as apiRoles } from '@igotweb/core-api/src/roles';

import { useGenericPage } from '@fwk-client/composables/useGenericPage';
import { useSiteAdmin } from '../../composables/useSiteAdmin';

import RoutesBox from '../panels/routes/Routes.vue';
import RouteForm from '../panels/routes/RouteForm.vue';
import Header from '../panels/Header.vue';

export default defineComponent({
  props: {
  },
  components: {
    PageTitle,
    RoutesBox,
    RouteForm,
    Header
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const genericPage = useGenericPage(props, context);
    
    const { sites, selectedSite } = useSiteAdmin(props, context);

    const siteContent:Ref<HTMLElement|null> = ref(null);

    const breadcrumbs = [{
      label: app.$t('home.dashboard')
    },
    {
      label: app.$t('cms.title')
    }]

    const hasUserRole = $store.getters['authentication/' + authenticationTypes.getters.HAS_USER_ROLE];
    const listLoading = computed(() => {
      return false;
    })

    const labels = {
      "addRouteTitle" : {
        "fr" : "Ajouter une page",
        "en" : "Add a page"
      }
    }

    onMounted(() => {
      enableIbox(siteContent.value!);
    })

    onBeforeUnmount(() => {
      disableIbox(siteContent.value!);
    })

    return {
      siteContent,
      breadcrumbs,
      sites, 
      selectedSite,
      hasUserRole,
      apiRoles,
      listLoading,
      labels
    }
  }
});
</script>