<template>
  
    <div>
      <div class="row" v-if="computedLabels.title">
        <div class="col-lg-12">
          <h3>{{ getLocalizedText(computedLabels.title) }}</h3>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3"></div>
        <div class="col-lg-9">
          <div class="row">
            <div class="col-lg-6 text-center p-xs" v-if="productionPath">
              <div v-if="computedLabels.production">{{ getLocalizedText(computedLabels.production) }}</div>
              <img v-if="type == 'picture'" :src="getStaticURLFromPath(productionPath)" height="50px" />
              <a v-else :href="getStaticURLFromPath(productionPath)" target="_blank">{{ getStaticURLFromPath(productionPath) }}</a>
            </div>
            <div :class="(productionPath ? 'col-lg-6' : 'col-lg-12') + ' text-center p-xs'" v-if="filePath">
              <div v-if="computedLabels.validation">{{ getLocalizedText(computedLabels.validation) }}</div>
              <img v-if="type == 'picture'" :src="getStaticURLFromPath(filePath)" height="50px" />
              <a v-else :href="getStaticURLFromPath(filePath)" target="_blank">{{ getStaticURLFromPath(filePath) }}</a>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="updateLogo" class="col-lg-3 col-form-label">{{ getLocalizedText(computedLabels.label) }}</label>
        <div class="col-lg-9">
          <div :class="{'input-group' : ($slots.buttons != undefined)}">
            <b-form-file
              v-model="form.file"
              :placeholder="getLocalizedText(computedLabels.placeholder)"
              :drop-placeholder="getLocalizedText(computedLabels.drop_placeholder)"
            />
            <span class="input-group-append" v-if="$slots.buttons"> 
              <slot name="buttons"></slot>
            </span>
          </div>
        </div>
      </div>
    </div>

</template>

<style>

</style>

<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { CmsContent, CmsEnum, CmsLabel, CmsPicture, CmsText, CmsFile, CmsNumber } from '@fwk-client/modules/cms/types';

/** @cmsLabels */
export interface FileLabels {
  /** @cmsType CmsLabel */
  title?:CmsLabel;
  /** @cmsType CmsLabel */
  label?:CmsLabel;
  /** @cmsType CmsLabel */
  production?:CmsLabel;
  /** @cmsType CmsLabel */
  validation?:CmsLabel;
  /** @cmsType CmsLabel */
  placeholder?:CmsLabel;
  /** @cmsType CmsLabel */
  drop_placeholder?:CmsLabel;
}

export interface FileSlots {
}

export default defineComponent({
  props: {
      file: {
        type: [Object,String] as PropType<any|String>,
        required: false,
        default: null
        
      },
      productionFile: {
        type: Object,
        required: false,
        default: null
      },
      type: {
        type: String as PropType<"file"|"picture">,
        default: "file"
      },
      labels: {
        type: Object as PropType<FileLabels>,
        default: () => { return {} }
      },
      components: Object as PropType<FileSlots>
  },
  components: {
    
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { file, productionFile, type } = toRefs(props);

    const filePath = computed(() => {
      return getPathFromFile(file.value);
    })

    const productionPath = computed(() => {
      return getPathFromFile(productionFile.value);
    })

    const getPathFromFile = (file:any) => {
      if(file && file.original) {
        return file.original.path;
      }
      else if(typeof file == "string") {
        return file;
      }
      return null;
    }

    const computedLabels:FileLabels = type.value == "picture" ? {
      "placeholder" : {
        "fr" : "Image",
        "en" : "Picture"
      },
      "drop_placeholder" : {
        "fr" : "Image",
        "en" : "Picture"
      },
      ...props.labels
    } : {
      "placeholder" : {
        "fr" : "Fichier",
        "en" : "File"
      },
      "drop_placeholder" : {
        "fr" : "Fichier",
        "en" : "File"
      },
      ...props.labels
    }

    const form:any = reactive({
      file : null
    });

    watch(
      form,
      (val:any, oldVal:any) => {
        context.emit('update:value', form.file);
      },
      { deep: true }
    )

    watch(
      file,
      (val:any, oldVal:any) => {
        form.file = null;
      },
      { deep: true }
    )

    return {
      file,
      productionFile,
      type,
      form,
      computedLabels,
      filePath,
      productionPath
    }

  }
})
</script>